import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/iconify/Iconify';
import { apis } from '../../apis/apis';

const History = () => {
  const navigate = useNavigate();

  const [show, setshow] = useState(false);
  const [data, setData] = useState(null);
  const [initialData, setInitialData] = useState(format(new Date().getTime(), 'yyyy-MM-dd'));
  const [endData, setEndData] = useState(format(new Date().getTime(), 'yyyy-MM-dd'));
  const [obra, setObra] = useState('');
  const [messages, setMessage] = useState('');

  const [rowsPerpage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, handlePage, newPage) => handlePage(newPage);

  const handleLoad = async () => {
    try {
      setshow(true)
      const res = await fetch(`${apis.actas}${initialData}/${endData}/${obra}`);
      const { data, result, message } = await res.json();
      if (result) {
        setData(data);
      } else {
        setMessage(message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleActa = (e) => {
    setObra(e.target.value);
  };
  const handlePage = (id) => {
    navigate(`/dashboard/actas?idacta=${id}`);
  };

  return (
    <Box p={1}>
      <Helmet>
        <title> Histórico | Actas </title>
      </Helmet>
      <Stack spacing={1} direction={'column'}>
        <Typography marginBottom={2} variant="h4" fontWeight={600} align="center" color="#9a0415">
          Histórico de Actas
        </Typography>
        <Divider />
        <Box display={'flex'} gap={'10px'} pt={'20px'}>
          <TextField
            onChange={(e) => setInitialData(e.target.value)}
            size="small"
            name="dateInit"
            type="date"
            color="solciviles"
            value={initialData}
            label="Fecha de corte"
            inputProps={{
              max: new Date().toISOString().split('T')[0],
            }}
          />
          <TextField
            onChange={(e) => setEndData(e.target.value)}
            size="small"
            name="dateInit"
            type="date"
            color="solciviles"
            value={endData}
            label="Fecha de corte"
            inputProps={{
              max: new Date().toISOString().split('T')[0],
            }}
          />
          <TextField
            onChange={handleActa}
            size="small"
            name="acta"
            type="text"
            color="solciviles"
            value={obra}
            label="Acta"
            required
          />
          <Button size="medium" variant="contained" color="solciviles" onClick={() => handleLoad()} disabled={obra.length <= 3}>
            Consultar
          </Button>
        </Box>
      </Stack>
      {show ? (
        <Stack justifyItems={'center'} alignContent={'center'} mt={3}>
          {data === null ?
            (
              <Stack justifyContent={'center'} alignItems={'center'} minHeight={500}>
                <Typography variant="h5"> {messages.toLocaleUpperCase()}</Typography>
              </Stack>
            )
            :
            (
              <>
                <Table size="small" aria-label="a dense table" padding="normal">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Obra</TableCell>
                      <TableCell align="center">ID Acta</TableCell>
                      <TableCell align="center">Responsable </TableCell>
                      <TableCell align="right">Valor del acta</TableCell>
                      <TableCell align="center">Propietario</TableCell>
                      <TableCell align="center">NIT</TableCell>
                      <TableCell align="center">Fecha</TableCell>
                      <TableCell align="center">Ver</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.slice(page * rowsPerpage, page * rowsPerpage + rowsPerpage).map((item, index) => (
                      <TableRow key={index} hover>
                        <TableCell align="center">{item.nombreobra}</TableCell>
                        <TableCell align="center">{item.idacta}</TableCell>
                        <TableCell align="center">{item.responsable}</TableCell>
                        <TableCell align="right">
                          {item.valoracta.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell align="center">{item.propietarios[0].nombrePropietario}</TableCell>
                        <TableCell align="center">{item.propietarios[0].nitPropietario}</TableCell>
                        <TableCell align="center">{item.fechaacta}</TableCell>
                        <TableCell align="center">
                          <IconButton color="primary" onClick={() => handlePage(item.idacta)}>
                            <Iconify icon="eva:eye-fill" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                  labelRowsPerPage="Filas por página"
                  sx={{ px: 2 }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={data?.length}
                  rowsPerPage={rowsPerpage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previa página',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Siguiente página',
                  }}
                  onPageChange={(e, page) => handleChangePage(e, setPage, page)}
                  onRowsPerPageChange={({ target: { value } }) => {
                    setRowsPerPage(value);
                    setPage(0);
                  }}
                />
              </>
            )
          }
        </Stack>
      ) : (
        <Stack justifyContent={'center'} alignItems={'center'} minHeight={500}>
          <Typography variant="h5">  No hay actas seleccionadas</Typography>
        </Stack>
      )}
    </Box>
  );
};

export default History;

