import { Helmet } from 'react-helmet-async';
import {
  Alert,
  Avatar,
  Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Slide, Snackbar, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AttachFiles from './Modals/AttachFiles';
import { BASE_URL_PRO, apis } from '../../apis/apis';
import TableBillsActas from './TableBillsActas';
import Loader from './Modals/Loader';


const styledResum = {

  justifyContent: 'space-between',
  gap: { xs: 0, sm: 1 },
  flexDirection: { xs: 'column', sm: 'row' },
  borderBottom: '1px solid gray', paddingY: '5px', ":hover": { borderBottom: '1px solid #9a0415', backgroundColor: '#d9d9d9', "& > *": { color: '#9a0415', fontWeight: 'bold' } }
}
const Actas = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [messages, setMessage] = useState('');
  const [attachFilesLocal, setAttachFilesLocal] = useState([]);
  const [attachFilesActa, setAttachFilesActa] = useState([]);
  const handleCloseModal = () => {
    setAttachFilesLocal(attachFilesActa)
    setShowModal(false)
  };
  const handleDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    link.click();
  };
  const downloadAllFiles = (facturaDoc) => {
    const FilesArray = facturaDoc || data?.documentosActa;
    FilesArray.forEach((file, index) => {
      setTimeout(() => {
        handleDownload(file);
      }, index * 1500);
    });
  };
  const handleGetActa = async (id) => {
    try {
      setLoading(true);
      const res = await fetch(`${apis.actas}${id}`);
      const { data, result, message } = await res.json();
      if (result) {
        setdata(data);
        setAttachFilesLocal(data.documentosActa);
        setAttachFilesActa(data.documentosActa);

      } else {
        setMessage(message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Obtener la URL actual
    const url = new URL(window.location.href);
    // Obtener el valor de idacta de los parámetros de la URL
    const idacta = url.searchParams.get('idacta');
    handleGetActa(idacta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [rowsPerpage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, handlePage, newPage) => {
    handlePage(newPage);
  };

  // image logo
  const [fileExists, setFileExists] = useState(null);
  const baseImageLogoURl = `${BASE_URL_PRO}assets/images/docs/img/logos/obras/${data?.idobra}`;
  const imagesExtension = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'svg', 'jpg', 'ico', 'bmp', 'tiff'];

  const FileExistsChecker = (imageLogoURl, extensions) => {
    const img = new Image();
    let currentIndex = 0;
    const checkImage = () => {
      if (currentIndex < extensions.length) {
        const currentExtension = extensions[currentIndex];
        img.src = `${imageLogoURl}.${currentExtension}`;
        // eslint-disable-next-line no-plusplus
        currentIndex++;
      } else {
        setFileExists(false); // Si agotamos todas las extensiones y ninguna funciona
      }
    };
    img.onload = () => {
      const successfulExtension = extensions[currentIndex - 1]; // La extensión que fue exitosa
      setFileExists({ exists: true, extension: successfulExtension });
    };
    img.onerror = () => {
      // Intentar con la siguiente extensión
      checkImage();
    };
    // Comenzar el proceso de verificación
    checkImage();
  };

  useEffect(() => {
    if (data) {
      FileExistsChecker(baseImageLogoURl, imagesExtension);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, baseImageLogoURl]);;


  // Redirect to acta
  const handleChange = (event) => {
    navigate(`/dashboard/actas?idacta=${event.target.value}`);
    window.location.reload();
  };
  // descarga de archivos pdf
  const [openLoader, setOpenLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    autoHideDuration: 3000,
  });
  const handleCloseSnackbar = () => setOpenSnackbar(false);
  async function getAndDownloadFiles() {
    setOpenLoader(true);
    try {
      const res = await fetch(`${apis.actas}combine/${data.idacta}`);
      const response = await res.json();
      if (response.data !== '') {
        setOpenSnackbar({
          open: true,
          message: 'Preparando archivos para descarga.',
          severity: 'warning',

        });
        const tempLink = document.createElement('a');
        tempLink.href = `data:application/pdf;base64,${response.data}`;
        tempLink.setAttribute('download', `DOCUMENTOS_ACTA_NUMERO_${data.idacta}.pdf`);
        tempLink.click();
        return
      }
      setOpenSnackbar({
        open: true,
        message: 'Sin archivos adjutos para descargar',
        severity: 'warning',
      });
    } catch (error) {
      console.log(error);
      setOpenSnackbar({
        open: true,
        message: 'Error al descargar',
        severity: 'error',
      })
    } finally {

      setOpenLoader(false);
    }
  }
  return (
    <Box p={1}>
      <Helmet>
        <title> Actas | Solciviles </title>
      </Helmet>
      <Stack pb={2} flexDirection={'row'} alignItems={'center'} >
        <Avatar
          sx={isMobile ? { width: '80px', height: '80px', } : { width: '120px', height: '120px' }}
          alt="Remy Sharp"
          src={fileExists ? `${baseImageLogoURl}.${fileExists.extension}` : `/assets/imagenofound.webp`}
          variant='square'
          imgProps={{ style: { objectFit: 'contain' } }}
        />
        <Typography variant={isMobile ? 'h5' : 'h4'} fontWeight={600} align="center" color="#9a0415" flexGrow={1}>
          {data?.numacta ? `${data?.nombreobra} ${data?.tituloActa}` : 'Sin información'}
        </Typography>
      </Stack>
      <Divider />
      <Snackbar
        open={openSnackbar.open}
        onClose={handleCloseSnackbar}
        TransitionComponent={Slide}
        message={openSnackbar.message}
        key={openSnackbar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}

      >
        <Alert variant='filled' severity="info">
          {openSnackbar.message}
        </Alert>
      </Snackbar>

      {!loading ? (
        data !== null ? (
          <>
            <Stack p={2} spacing={1} direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'}>
              <Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} gap={1}>
                  <Typography fontWeight={700}>
                    Obra:
                  </Typography>
                  <Typography>
                    {data?.nombreobra}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} gap={1}>
                  <Typography fontWeight={700}>
                    Nº de acta:
                  </Typography>
                  <Typography>
                    {data.numacta}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} gap={1} alignItems={!isMobile && 'center'}>
                  <Typography fontWeight={700}>
                    Actas anteriores:
                  </Typography>
                  {data?.actasAnt &&
                    data?.actasAnt.length > 0 ?
                    <FormControl sx={{ m: 1, width: 180, margin: 0 }} size='small'  >
                      <InputLabel id="label actas"  >Seleccione</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Actas anteriores"
                        size='small'
                        onChange={handleChange}
                        value={''}
                      >

                        {data?.actasAnt && data?.actasAnt.map((acta) => (
                          <MenuItem
                            key={acta.idActaEncrypt}
                            value={acta.idActaEncrypt}
                          >
                            Acta N° {acta.numacta} - Fecha {acta.fechaacta}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    :
                    <Typography color={'gray'} variant="body2">Sin actas anteriores</Typography>
                  }
                </Stack>
              </Stack>
              <Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} gap={1}>
                  <Typography fontWeight={700}>
                    Estatus:
                  </Typography>
                  <Typography>
                    {data.estado === 'C' ? 'Cerrada' : 'Abierta'}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} gap={1}>
                  <Typography fontWeight={700}>
                    Fecha de cierre:
                  </Typography>
                  <Typography>
                    {data.fechaacta}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} gap={1}>
                  <Typography fontWeight={700}>
                    Número de Cuenta de Cobro:
                  </Typography>
                  <Typography>
                    {data.numdctocobro}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Box mt={3}>
                <Stack
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent={'space-between'}
                  border={1}
                  borderColor={'grey.400'}
                  p={2}
                  borderRadius={1}
                  sx={{ backgroundColor: '#d9d9d9' }}
                >
                  <Stack direction="column" spacing={1} justifyContent={'center'} flexBasis={'100%'} >
                    {
                      isMobile ? (
                        <Stack >
                          {
                            data.propietarios.map(propietario => (
                              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} key={propietario.idPropietario}>
                                <Typography fontWeight={700}>
                                  {data.propietarios.length > 1 ? "Dueños" : "Dueño"}
                                </Typography>
                                <Typography>
                                  {propietario.nombrePropietario}
                                </Typography>
                                <Typography fontWeight={700}>
                                  Nit
                                </Typography>
                                <Typography>
                                  {propietario.nitPropietario}
                                </Typography>
                                <Typography fontWeight={700}>
                                  % de participación
                                </Typography>
                                <Typography>
                                  {propietario.propiedad}%
                                </Typography>
                                <Typography fontWeight={700}>
                                  Autoretenedor
                                </Typography>
                                <Typography>
                                  {propietario.autoretenedor ? 'Sí' : 'No'}
                                </Typography>
                                <Divider sx={{ marginBottom: 1, marginTop: 1, backgroundColor: '#4d4d4d', display: data.propietarios.length > 1 ? 'block' : 'none' }} />
                              </Stack>
                            ))
                          }
                        </Stack>
                      ) :
                        <Table>
                          <TableHead >
                            <TableRow  >
                              <TableCell sx={{ padding: 0, background: 'none', border: 'none', color: 'black' }}>
                                <Typography fontWeight={700} fontSize={'14px'}>

                                  {data.propietarios.length > 1 ? "Dueños" : "Dueño"}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ padding: 0, background: 'none', border: 'none', color: 'black' }}>
                                <Typography fontWeight={700} fontSize={'14px'}>
                                  Nit
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ padding: 0, background: 'none', border: 'none', color: 'black' }} align='right'>
                                <Typography fontWeight={700} fontSize={'14px'}>
                                  % de participación
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ padding: 0, background: 'none', border: 'none', color: 'black' }} align='right'>
                                <Typography fontWeight={700} fontSize={'14px'}>
                                  Autoretenedor
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              data.propietarios.map(propietario => (
                                <TableRow key={propietario.idPropietario}>
                                  <TableCell sx={{ padding: 0, border: 'none', color: '#9a0415', fontWeight: 700 }}  >
                                    {propietario.nombrePropietario}
                                  </TableCell>
                                  <TableCell sx={{ padding: 0, border: 'none' }}>
                                    {propietario.nitPropietario}
                                  </TableCell>
                                  <TableCell sx={{ padding: 0, border: 'none' }} align='right'>
                                    {propietario.propiedad}%
                                  </TableCell>
                                  <TableCell sx={{ padding: 0, border: 'none' }} align='right'>
                                    {propietario.autoretenedor ? 'Sí' : 'No'}
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                    }
                  </Stack>
                </Stack>
                <Stack p={2} direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 1 }}>
                  <Typography fontWeight={700}>
                    Descripción:
                  </Typography>
                  <Typography>
                    Pagos a terceros por la construción de su obra {data.nombreobra} por el sistema de  {data.tipoobra === 'A' ? 'administración delegada' : 'precios unitarios fijos'}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent={'space-between'} p={2}>
                  <Stack direction={'row'} justifyContent={'space-between'} spacing={1} flexBasis={{ xs: 'auto', sm: '55%' }} >
                    <Stack flexGrow={1} >
                      <Stack sx={styledResum}>
                        <Typography variant="body2" fontWeight={700} >
                          Acumulado Actas anteriores:
                        </Typography>
                        <Typography variant="body2"  >
                          {data.actasanteriores.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Typography>
                      </Stack>
                      <Stack sx={styledResum}>
                        <Typography variant="body2" fontWeight={700}>
                          Valor al Acta:
                        </Typography>
                        <Typography variant="body2">
                          {data.valoracta.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Typography>
                      </Stack>
                      <Stack sx={styledResum}>
                        <Typography variant="body2" fontWeight={700}>
                          Acumulados Actas Actual:
                        </Typography>
                        <Typography variant="body2">
                          {(data.of_acu_aa).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Typography>
                      </Stack>
                      <Stack sx={styledResum}>
                        <Typography variant="body2" fontWeight={700}>
                          Acumulados Pagos Anteriores:
                        </Typography>
                        <Typography variant="body2">
                          {data.pagosanteriores.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Typography>
                      </Stack>
                      <Stack sx={styledResum}>
                        <Typography variant="body2" fontWeight={700}>
                          Pagos al Acta:
                        </Typography>
                        <Typography variant="body2">
                          {data.pagosalacta.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Typography>
                      </Stack>
                      <Stack sx={styledResum} >
                        <Typography variant="body2" fontWeight={700}>
                          Acumulado Pagos Actual:
                        </Typography>
                        <Typography variant="body2">
                          {(data.of_acu_pa).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack border={1} justifyContent={'center'} borderColor={'grey.400'} p={2} borderRadius={1} alignItems={'center'} >
                    <Stack justifyContent={'space-between'} flexDirection={{ xs: 'column', sm: 'row' }} gap={1} >
                      <Typography variant="body2" fontWeight={700}>
                        Valor a Pagar:{' '}
                      </Typography>
                      <Typography variant="body2" fontWeight={700}>
                        {(data?.of_valorapagar).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Stack>
                    <Stack justifyContent={'space-between'} flexDirection={{ xs: 'column', sm: 'row' }} gap={1} >
                      <Typography variant="body2" fontWeight={700}>
                        Saldo a Favor{' '}
                      </Typography>
                      <Typography variant="body2" fontWeight={700}>
                        {(data?.of_saldoafavor).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                {/* Adjuntos */}
                <Stack direction={'row'} justifyContent={'end'} p={2} alignItems={'center'} flexWrap={'wrap'} gap={2}>
                  <Button onClick={() => setShowModal(true)} variant='contained' sx={{ textTransform: 'none' }}>Documentos del acta</Button>
                  <Button onClick={() => getAndDownloadFiles()} variant='outlined' sx={{ textTransform: 'none' }}
                  >Descargar todos los documentos</Button>
                </Stack>
                {/* Fcturas */}
              </Box>
              <Divider />
              {
                data?.facturas !== null ? (
                  <>

                    <Box my={3}>
                      <Typography mt={2} fontWeight={'bold'} align="center" variant="h6" color="#9a0415">
                        Facturas asociadas al acta
                      </Typography>
                    </Box>
                    {
                      data?.facturas.length > 0 ?
                        (
                          <Stack border={1} borderColor={'grey.300'} p={2} borderRadius={1}>
                            <TableBillsActas data={data} page={page} rowsPerpage={rowsPerpage} setAttachFilesLocal={setAttachFilesLocal} downloadAllFiles={downloadAllFiles} setShowModal={setShowModal} attachFilesActa={attachFilesActa} />
                            <TablePagination
                              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                              labelRowsPerPage="Filas por página"
                              sx={{ px: 2 }}
                              rowsPerPageOptions={[25, 50, 75, 100]}
                              component="div"
                              count={data?.facturas?.length}
                              rowsPerPage={rowsPerpage}
                              page={page}
                              backIconButtonProps={{
                                'aria-label': 'Previa página',
                              }}
                              nextIconButtonProps={{
                                'aria-label': 'Siguiente página',
                              }}
                              onPageChange={(e, page) => handleChangePage(e, setPage, page)}
                              onRowsPerPageChange={({ target: { value } }) => {
                                setRowsPerPage(value);
                                setPage(0);
                              }}
                            />
                          </Stack>
                        )
                        :
                        <Stack justifyContent={'center'} alignItems={'center'} minHeight={300}>
                          <Typography variant="h5"> Sin facturas asociadas</Typography>
                        </Stack>
                    }
                  </>
                )
                  :
                  <Stack justifyContent={'center'} alignItems={'center'} minHeight={300}  >
                    <Typography variant="h5"> Sin facturas asociadas</Typography>
                  </Stack>
              }
            </Stack>
          </>
        ) : (
          <Stack justifyContent={'center'} alignItems={'center'} minHeight={500}>
            <Typography variant="h5"> {messages.toLocaleUpperCase()}</Typography>
          </Stack>
        )
      ) : (
        <Stack justifyContent={'center'} alignItems={'center'} minHeight={500}>
          <Typography variant="h5"> Cargando...</Typography>
        </Stack>
      )}
      {
        showModal && (
          <AttachFiles
            open={showModal}
            handleClose={handleCloseModal}
            attachFilesLocal={attachFilesLocal}
            handleDownload={handleDownload}
            downloadAllFiles={downloadAllFiles}
          />
        )
      }
      {openLoader &&
        <Loader open={openLoader} />
      }
    </Box >
  );
};

export default Actas;

