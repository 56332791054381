import { Button, Card, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import Iconify from '../../../components/iconify/Iconify';

const ImageModal = ({ file, handleClose, open, handleDownload }) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
    <DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.solciviles.main,
        }}
      >
        <Iconify icon="ic:round-close" />
      </IconButton>
      {file.name}
    </DialogTitle>
    <DialogContent dividers>
      <Card  >
        <CardMedia component="img" alt="Card Image" image={file.url} />
      </Card>
    </DialogContent>

    <DialogActions>
      <Button
        onClick={handleDownload}
        download
        color="success"
        variant="outlined"
      >
        Descargar
      </Button>

      <Button onClick={handleClose} variant="outlined" color="solciviles">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
);

export default ImageModal;
