import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, TableCell, TableHead, TableRow, Typography, Table, TableBody, DialogActions, } from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import ExportExcel from '../../../components/exportExcel/ExportExcel';
import { apis } from '../../../apis/apis'


const ModalBillComprobante = ({ open, handleClose, idComprobante }) => {
  const [isLoading, setIsloading] = useState(false)
  const [data, setData] = useState(null)
  const [showMessage, setShowMesage] = useState(null)
  const getData = async () => {
    setIsloading(true)
    try {
      const res = await fetch(`${apis.comprobantes}${idComprobante}`)
      const { data, result, message } = await res.json()
      if (result) {
        setData(data)
      } else {
        setShowMesage(message)
      }
    } catch (error) {
      setShowMesage('Error al consultar')
    }
    finally {
      setIsloading(false)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const matches = useMediaQuery('(max-width:1280px)');
  const totales = useMemo(() => data?.facturas?.reduce((totals, bill) => {
    totals.causado += bill.causado;
    totals.anticipo += bill.anticipo;
    totals.retenido += bill.retenido;
    totals.deducciones += bill.deducciones;
    totals.descuento += bill.descprontopago + bill.descpiefactura;
    totals.retefuente += bill.retefuente;
    totals.iva += bill.iva;
    totals.reteiva += bill.reteiva;
    totals.reteica += bill.reteica;
    totals.valorpago += bill.valorpago;
    totals.valoracta += bill.valoracta;
    return totals;
  }, {
    causado: 0,
    anticipo: 0,
    retenido: 0,
    deducciones: 0,
    descuento: 0,
    retefuente: 0,
    iva: 0,
    reteiva: 0,
    reteica: 0,
    valorpago: 0,
    valoracta: 0,

  }), [data?.facturas]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'xl'}>
      <DialogTitle>Facturas relacionadas {idComprobante}</DialogTitle>
      <DialogContent>
        {data &&
          <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} >
              <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Typography fontWeight={700} fontSize={'1rem'}>Tipo: </Typography>
                <Typography fontSize={'1rem'}> {data?.tipo}</Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Typography fontWeight={700} fontSize={'1rem'}>Cheque: </Typography>
                <Typography fontSize={'1rem'}> {data?.cheque ? 'Si' : 'No'}</Typography>
                <Typography fontSize={'1rem'} style={{ display: data?.cheque ? 'block' : 'none' }}> {data?.cheque ? data.numcheque : ''}</Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Typography fontWeight={700} fontSize={'1rem'}>Cuenta bancaria: </Typography>
                <Typography fontSize={'1rem'}> {data?.cuentabancaria ? data.cuentabancaria : 'No disponible'}</Typography>
              </Box>
            </Box>
            <Box>
              <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Typography fontWeight={700} fontSize={'1rem'}>Fecha:</Typography>
                <Typography fontSize={'1rem'}>{data?.fechacomprobante}</Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Typography fontWeight={700} fontSize={'1rem'}>Forma de pago:</Typography>
                <Typography fontSize={'1rem'}>{data?.formadepago}</Typography>
              </Box>
            </Box>
          </Box>
        }
        <Box my={3}>
          <Typography mt={2} fontWeight={'bold'} align="center" variant="h6" color="#9a0415">
            Facturas asociadas
          </Typography>
        </Box>
        {
          isLoading ?
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
            :
            data ?
              <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} gap={2} >
                {!matches ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell>N° documento</TableCell>
                        <TableCell align='right'>Causado</TableCell>
                        <TableCell align='right'>Anticipo</TableCell>
                        <TableCell align='right'>Retenido</TableCell>
                        <TableCell align='right'>Deducción</TableCell>
                        <TableCell align='right'>Descuento</TableCell>
                        <TableCell align='right'>Rte/fte</TableCell>
                        <TableCell align='right'>IVA</TableCell>
                        <TableCell align='right'>Rte/Iva</TableCell>
                        <TableCell align='right'>Rte/Ica</TableCell>
                        <TableCell align='right'>Valor Pago</TableCell>
                        <TableCell align='right'>Valor Acta</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.facturas?.map((bill, index) => (
                        <TableRow key={index}>
                          <TableCell>{bill.fecha}</TableCell>
                          <TableCell>{bill.descripcion.trim()}</TableCell>
                          <TableCell>{bill.nrodctopro.trim()}</TableCell>
                          <TableCell align='right'>{bill.causado.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.anticipo.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.retenido.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.deducciones.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{(bill.descprontopago + bill.descpiefactura).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.retefuente.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.iva.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.reteiva.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.reteica.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>

                          <TableCell align='right'>{bill.valorpago.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>
                          <TableCell align='right'>{bill.valoracta.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</TableCell>

                        </TableRow>))}
                      {/* Totales */}
                      <TableRow style={{ borderTop: '2px solid gray' }}>
                        <TableCell colSpan={2}><Typography fontWeight={600}>Total</Typography></TableCell>
                        <TableCell />
                        {Object.values(totales).map((total, index) => (
                          <TableCell key={index} align='right'>
                            {total.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </TableCell>
                        )
                        )}
                      </TableRow>

                    </TableBody>
                  </Table>
                  :
                  <>
                    {data.facturas?.map((bill, index) => (
                      <Stack
                        key={index}
                        direction={'row'}
                        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                        spacing={{ xs: 0, sm: 2, md: 2 }}
                        p={2}
                        flexWrap={'wrap'}
                        gap={1}
                        sx={{ border: '1px solid #aeaeae', borderRadius: '4px' }}
                      >
                        <Stack direction={'column'} spacing={0} flexGrow={{ xs: 1, sm: 0 }}>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Fecha
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.fecha}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Descripción
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.descripcion.trim()}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              N° documento
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.nrodctopro.trim()}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Causado
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.causado.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Anticipo
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.anticipo.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Retenido
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.retenido.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Deduccion
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.deducciones.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>

                        </Stack>
                        <Stack direction={'column'} spacing={0} flexGrow={{ xs: 1, sm: 0 }}>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Descuento
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {(bill.descprontopago + bill.descpiefactura).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Rte/fte
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.retefuente.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              IVA
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.iva.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Rte/Iva
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.reteiva.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Rte/Ica
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.reteica.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>

                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Valor Pago
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.valorpago.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                            <Typography fontWeight={700} fontSize={'0.8rem'}>
                              Valor Acta
                            </Typography>
                            <Typography fontSize={'0.8rem'}>
                              {bill?.valoracta.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))
                    }
                    <Stack
                      direction={'row'}
                      justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                      spacing={{ xs: 0, sm: 2, md: 2 }}
                      p={2}
                      flexWrap={'wrap'}
                      gap={1}
                      sx={{ border: '1px solid #9a0415', borderRadius: '4px' }}
                    >
                      <Stack direction={'column'} spacing={0} flexGrow={{ xs: 1, sm: 0 }}>
                        <Stack direction={'row'} justifyContent={'center'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.9rem'}>
                            Totales
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Causado
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.causado.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Anticipo
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.anticipo.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Retenido
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.retenido.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Deduccion
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.deducciones.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Descuento
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.descuento.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction={'column'} spacing={0} flexGrow={{ xs: 1, sm: 0 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Rte/fte
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.retefuente.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            IVA
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.iva.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Rte/Iva
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.reteiva.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Rte/Ica
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.reteica.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Valor Pago
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.valorpago.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Valor Acta
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {totales?.valoracta.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </>
                }
              </Box>
              :
              <Box my={3}>
                <Typography mt={2} fontWeight={'bold'} align="center" variant="h6" color="#9a0415">
                  {showMessage}
                </Typography>
              </Box>
        }
      </DialogContent>
      <DialogActions>

        <ExportExcel data={data} />
      </DialogActions>
    </Dialog>
  )
}

export default ModalBillComprobante
