// const BASE_URL = 'http://solciviles.tresdtech.com:9003/'
export const BASE_URL = 'https://core-solciviles.tresdtech.com/'
export const BASE_URL_PRO = 'https://solciviles.tresdtech.com/'

const actas = 'actas/'
const buscar = 'buscar/'
const compronte = 'comprobante/'

export const apis = {
  actas: `${BASE_URL}${actas}`,
  comprobantes: `${BASE_URL}${actas}${buscar}${compronte}`
}
