import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';


const Logo = forwardRef(({ disabledLink = true, sx, }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="img"
      src="/assets/logo.webp"
      sx={{ cursor: '', ...sx }}
      loading='lazy'
    />
  );


  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});


export default Logo;
