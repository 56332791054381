import { Button } from '@mui/material';
import { utils, writeFile } from 'sheetjs-style'


const ExportExcel = ({ data }) => {

  const facturas = data?.facturas.map(bill => ({
    Comprobante: data.idcomprobante,
    "Tipo de comprobante": data.tipo,
    "Fecha de comprobante": data.fechacomprobante,
    'Forma de pago': data.formadepago,
    'Cheque': data.cheque,
    'Numero de Cheque': data.numcheque,
    'Cuenta bancaria': data.cuentabancaria,
    fecha: bill.fecha,
    descripcion: bill.descripcion.trim(),
    causado: bill.causado,
    anticipo: bill.anticipo,
    retenido: bill.retenido,
    deducciones: bill.deducciones,
    descuento: bill.descprontopago + bill.descpiefactura,
    "Rte/fte": bill.retefuente,
    iva: bill.iva,
    "Rte/Iva": bill.reteiva,
    "Rte/Ica": bill.reteica,
    "Valor Paga": bill.valorpago,
    "Valor Acta": bill.valoracta,
  }))

  const exportData = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(facturas);
    utils.book_append_sheet(wb, ws, `comprobante-${data.idcomprobante}`)
    writeFile(wb, `comprobante-${data.idcomprobante}.xlsx`)
  }
  return (
    <Button
      onClick={exportData}
      variant="contained"
      type="button"
    >
      Exportar a Excel
    </Button>
  )
}

export default ExportExcel