import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
//
import { Container } from '@mui/material';
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;
const APP_BAR_MOBILE = 32;
const APP_BAR_DESKTOP = 12;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  backgroundColor: alpha(theme.palette.grey[400], 0.15),
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(prev => !prev);
  return (
    <StyledRoot >
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={handleOpen} />

      <Main sx={{ backgroundColor: '#d9d9d9' }} >
        <Container sx={{ backgroundColor: 'white', height: '100%', p: 2, borderRadius: 1 }} maxWidth={'1600px'}>
          <Outlet />
        </Container>
      </Main>
    </StyledRoot>
  );
}
