import React from 'react'
import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@mui/material'

const Loader = ({ open }) => (
  <Dialog open={open}>
    <DialogTitle>Generando PDF</DialogTitle>
    <DialogContent>
      <LinearProgress />
    </DialogContent>
  </Dialog>
)

export default Loader