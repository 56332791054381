import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,

} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


import Iconify from '../../../components/iconify/Iconify';
import ImageModal from './ImageModal';

const AttachFiles = ({ open, handleClose, attachFilesLocal, handleDownload, downloadAllFiles }) => {
  const [file, setFile] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const handleCloseImage = () => setOpenImage(false);
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const regex = /\/([^/]+)\.[^.]+$/;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" >
      <DialogTitle  >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent"
            },
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.solciviles.main,
          }}
        >
          <Typography fontSize={'16px'} color='#9a0415'>
            Cerrar
          </Typography>
          <Iconify icon="ic:round-close" />
        </IconButton>{' '}
        Documentos adjuntos
      </DialogTitle>
      <DialogContent dividers>
        {/* <Box sm={4} xs={12} mb={1} p={2} display={'flex'} flexWrap={'wrap'} sx={{ gap: 1 }}> */}
        <Box  >
          {attachFilesLocal?.length > 0 ? (
            attachFilesLocal.map((file, index) => {
              const fileExtension = file.url.split('.').pop().toLowerCase();
              const match = file.url.match(regex);
              return (
                <Box key={index} >
                  {
                    ['jpg', 'png', 'jpeg', 'web', 'pdf', 'xls', 'xlsx', 'xlsm'].includes(fileExtension) ?
                      <Button
                        sx={{
                          marginBottom: 1,
                        }}
                        component={Link}
                        variant="contained"
                        type="button"
                        color="inherit"
                        startIcon={
                          (fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm') ?
                            (
                              <Iconify icon="teenyicons:ms-excel-outline" color="#049a0b87" />
                            ) : (fileExtension === 'pdf') ? (
                              <Iconify icon="teenyicons:pdf-outline" color="#9a0415" />
                            ) : (
                              <Iconify color="#2065D1" icon="ic:baseline-image" />
                            )
                        }
                        onClick={() => {
                          if (fileExtension === 'pdf' || fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm') {
                            handleDownload(file);
                          }
                          else if (['jpg', 'png', 'jpeg', 'web'].includes(fileExtension)) {
                            setOpenImage(true);
                            setFile(file);
                          }
                        }}
                      >
                        <Typography component={'span'} variant="body2">

                          {isMobile ? match ? `${match[1].slice(0, 30)}...` : 'Ninguna' : match ? match[1] : 'Ninguna'}
                        </Typography>
                      </Button>
                      : <></>
                  }
                </Box>
              );
            })
          ) : (
            <Typography variant="h6" color="inherit" style={{ marginTop: 10, width: '100%' }} align={'center'} className="w-full">
              No existen documentos adjuntos.
            </Typography>
          )}
        </Box>
      </DialogContent>
      {attachFilesLocal?.length > 0 &&
        <DialogActions sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: 1, p: 1 }}>
          <Typography sx={{ paddingLeft: 2 }} fontSize={14} fontWeight={600}>
            Nota: Solo se previsualizan las imágenes.
          </Typography>
          <Stack spacing={1} direction={'row'}>
            <Button onClick={() => downloadAllFiles()} variant="contained" color="solciviles" sx={{ textTransform: 'none' }}>
              Descargar todos
            </Button>

          </Stack>
        </DialogActions>
      }
      {openImage && <ImageModal open={openImage} handleClose={handleCloseImage} file={file} handleDownload={handleDownload} />}
    </Dialog >
  );
};

export default AttachFiles;
